var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h3", [_vm._v("Verkrijgbaar in:")]),
              _c("multi-select", {
                attrs: { options: this.defs["Rights.Territory"].Options },
                model: {
                  value: _vm.territoryInclude,
                  callback: function($$v) {
                    _vm.territoryInclude = $$v
                  },
                  expression: "territoryInclude"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("h3", [_vm._v("Uitgesloten gebieden:")]),
              _c("multi-select", {
                attrs: {
                  options: this.defs["Rights.Territory"].Options.filter(
                    function(opt) {
                      return opt.JsonValue !== "WRD"
                    }
                  )
                },
                model: {
                  value: _vm.territoryExclude,
                  callback: function($$v) {
                    _vm.territoryExclude = $$v
                  },
                  expression: "territoryExclude"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            _vm._l(_vm.errors, function(error, index) {
              return _c(
                "b-alert",
                {
                  key: index,
                  staticClass: "mt-4",
                  attrs: { show: true, variant: "warning" }
                },
                [_vm._v(_vm._s(error))]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }